import { Navbar, Nav} from "react-bootstrap";
import logo from '../img/icons/broncos-logo.png'
import React, { useState} from "react";

export function NavbarBroncos() {

    const[navbarBroncos,setnavbarBroncos]= useState(false);

    const changeBackground=()=>{
        //console.log(window.scrollY)
        if(window.scrollY>0){
            setnavbarBroncos(true);
        }else{
            setnavbarBroncos(false);
        }
    }

    window.addEventListener('scroll',changeBackground)

    return (
        <Navbar sticky="top"
        className={navbarBroncos?'navbarBroncos active':'navbarBroncos'}
        expand="lg">

            <Navbar.Brand href="/" className="navbarTitle">
                <img src={logo} alt="logo" className="logoPrincipal"/>
                Club Broncos Bello
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/" className="navbarSubtitles">Inicio</Nav.Link>
                    <Nav.Link href="/Mi-Equipo" className="navbarSubtitles">Mi Equipo</Nav.Link>
                    <Nav.Link href="/#canchas" className="navbarSubtitles">Canchas</Nav.Link>
                    <Nav.Link href="/#footer" className="navbarSubtitles">Contacto</Nav.Link>
                </Nav>
            </Navbar.Collapse>

        </Navbar>
    )
}

