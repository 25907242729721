import { Row, Col } from "react-bootstrap";

import styles from './styles/canchas.module.css';

export function Canchas() {
    return (

        <Row>
            <a id="canchas"></a>
            <h1 className={styles.titulo}> Espacios de Entrenamiento</h1>
            <br /><br /><br /><br />
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <div>
                    <h3 className={styles.tituloCancha}>Auxiliar Tulio Ospina de Bello</h3>
                    <iframe className={styles.Canchas} id="gmap_canvas" src="https://maps.google.com/maps?q=Cancha%20Auxiliar%20Tulio%20Ospina&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="Auxiliar Tulio Ospina de Bello"></iframe>
                    <br />
                    <br />
                    <br />
                </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <div>
                    <h3 className={styles.tituloCancha}>Marte 2 Atanasio Girardot de Medellín</h3>
                    <iframe className={styles.Canchas} id="gmap_canvas" src="https://maps.google.com/maps?q=marte%202%20&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="Marte 2 Atanasio Girardot de Medellín"></iframe>
                    <br />
                    <br />
                    <br />
                </div>
            </Col>
        </Row>

    );
} 