import { Header } from '../components/header';
import { Whatsapp } from '../components/whatsapp';
import { SocialNetworks } from '../components/socialNetwork';
import { Footer } from '../components/footer';
import { EnConstruccion } from '../components/enConstruccion';
import { NavbarBroncos } from '../components/navbar';


export function MiEquipo() {
    return (
        <div>
            <NavbarBroncos/>
            <SocialNetworks />
            <EnConstruccion/>
            <Whatsapp />
            <Footer />
        </div>
    );
}