import { Container } from 'react-bootstrap';
import styles from './styles/enConstruccion.module.css';

export function EnConstruccion() {
    return (
        <div className={styles.Contenedor}>
            <Container>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <h1>Sitio en Construcción</h1>
                <p>Esta parte de la pagina aun esta en proceso de construcción. Esperamos muy pronto sacar este modulo. #WeAreNorth</p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Container>
        </div>
    );
}