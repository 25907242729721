import { Container } from "react-bootstrap";
import { Canchas } from "./canchas";

export function Contenido() {
    return (
        <div className="Contenedor">
            <Container>
                <br />
                <br />
                <br />
                <h1>Misión del Club</h1>
                <br />
                <p>Somos el equipo del Norte "We are the north", Somos el Club Broncos Bello de Football Americano en multiples categorias como Flag Femenino, Flag Juvenil y Tackle.
                    Nuestro objetivo como equipo es impactar socialmente a través del deporte, formar jovenes y adultos como deportistas que haran parte de varias competencias del municipio de medellin,de bello y ocasinalmente competencias nacionales. </p>
                <br />
                <br />
                <h1>Horarios</h1>
                <br />
                <h3>Flag Femenino y Juvenil</h3>
                <br />
                <ol>
                    <li>Lunes 9:00pm 11:00pm Auxiliar Unidad Deportiva Tulio Ospina, Bello</li>
                    <li>Miercoles 8:00pm 10:00pm Auxiliar Unidad Deportiva Tulio Ospina, Bello</li>
                    <li>Viernes 8:00pm 10:00pm Auxiliar Unidad Deportiva Tulio Ospina, Bello</li>
                    <li>Sabado 8:00pm 10:00pm Auxiliar Unidad Deportiva Tulio Ospina, Bello</li>
                </ol>
                <h3>Tackle</h3>
                <br />
                <ol>
                    <li>Lunes 9:00pm 11:00pm Auxiliar Unidad Deportiva Tulio Ospina, Bello</li>
                    <li>Martes 7:30pm 9:30pm Unidad Deportiva Atanasio Girardot, Medellín</li>
                </ol>
                <Canchas />
            </Container>
        </div>
    );
}