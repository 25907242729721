import { Col } from "react-bootstrap";


export function SocialNetworks() {
    return (
        <div class="fondoSocial">

            <Col md={12}>
                <br />
            </Col>
            <Col md={12}>
                <a href="https://www.instagram.com/broncosbello/" class="top-slim-social__item marginIconos" target="_blank" rel="noopener" data-analytics="true" data-analytics-type="social media" data-analytics-section="top menu" data-analytics-event="instagram-follow" data-analytics-initialised="true">
                    <span class="visually-hidden">Sigue al instagram</span>
                    <svg class="icon icon--instagram iconos" viewBox="0 0 169.063 169.063">
                        <path d="M122.406 0H46.654C20.929 0 0 20.93 0 46.655v75.752c0 25.726 20.929 46.655 46.654 46.655h75.752c25.727 0 46.656-20.93 46.656-46.655V46.655C169.063 20.93 148.133 0 122.406 0zm31.657 122.407c0 17.455-14.201 31.655-31.656 31.655H46.654C29.2 154.063 15 139.862 15 122.407V46.655C15 29.201 29.2 15 46.654 15h75.752c17.455 0 31.656 14.201 31.656 31.655v75.752z"></path>
                        <path d="M84.531 40.97c-24.021 0-43.563 19.542-43.563 43.563 0 24.02 19.542 43.561 43.563 43.561s43.563-19.541 43.563-43.561c0-24.021-19.542-43.563-43.563-43.563zm0 72.123c-15.749 0-28.563-12.812-28.563-28.561 0-15.75 12.813-28.563 28.563-28.563s28.563 12.813 28.563 28.563c0 15.749-12.814 28.561-28.563 28.561zM129.921 28.251c-2.89 0-5.729 1.17-7.77 3.22a11.053 11.053 0 00-3.23 7.78c0 2.891 1.18 5.73 3.23 7.78 2.04 2.04 4.88 3.22 7.77 3.22 2.9 0 5.73-1.18 7.78-3.22 2.05-2.05 3.22-4.89 3.22-7.78 0-2.9-1.17-5.74-3.22-7.78-2.04-2.05-4.88-3.22-7.78-3.22z"></path>
                    </svg>
                </a>

                <a href="https://www.facebook.com/broncosbello" class="top-slim-social__item marginIconos" target="_blank" rel="noopener" data-analytics="true" data-analytics-type="social media" data-analytics-section="top menu" data-analytics-event="facebook-follow" data-analytics-initialised="true">
                    <span class="visually-hidden">Sigue al facebook</span>
                    <svg class="icon icon--facebook iconos" viewBox="0 0 257.72 257.72">
                        <path d="M243.49 0H14.22A14.22 14.22 0 000 14.22v229.27a14.23 14.23 0 0014.22 14.23h123.44v-99.8h-33.59V119h33.59V90.34c0-33.29 20.32-51.42 50-51.42a273.05 273.05 0 0130 1.54v34.78H197.1c-16.15 0-19.28 7.68-19.28 18.94V119h38.52l-5 38.9h-33.5v99.8h65.67a14.23 14.23 0 0014.23-14.23V14.22A14.23 14.23 0 00243.49 0z"></path>
                    </svg>
                </a>

                <a href="#" class="top-slim-social__item marginIconos" target="_blank" rel="noopener" data-analytics="true" data-analytics-type="social media" data-analytics-section="top menu" data-analytics-event="youtube-follow" data-analytics-initialised="true">
                    <span class="visually-hidden">Sigue al youtube</span>
                    <svg class="icon icon--youtube iconos" viewBox="0 0 28 20.05">
                        <path d="M27.72 4.32a6.17 6.17 0 00-1.11-2.82A3.93 3.93 0 0023.8.29C19.88 0 14 0 14 0S8.12 0 4.2.29A3.93 3.93 0 001.39 1.5 6.17 6.17 0 00.28 4.32 44 44 0 000 8.94v2.16a43.69 43.69 0 00.28 4.61 6.19 6.19 0 001.11 2.83 4.7 4.7 0 003.09 1.22c2.24.22 9.52.29 9.52.29s5.88 0 9.8-.3a4 4 0 002.81-1.21 6.19 6.19 0 001.11-2.83A43.69 43.69 0 0028 11.1V8.94a44 44 0 00-.28-4.62zm-16.61 9.4v-8l7.56 4z"></path>
                    </svg>
                </a>

                <a href="https://twitter.com/BroncosBello" class="top-slim-social__item marginIconos" target="_blank" rel="noopener" data-analytics="true" data-analytics-type="social media" data-analytics-section="top menu" data-analytics-event="twitter-follow" data-analytics-initialised="true">
                    <span class="visually-hidden">Sigue al twitter</span>
                    <svg class="icon icon--twitter iconos" viewBox="0 0 512.002 512.002">
                        <path d="M512.002 97.211c-18.84 8.354-39.082 14.001-60.33 16.54 21.686-13 38.342-33.585 46.186-58.115a210.29 210.29 0 01-66.705 25.49c-19.16-20.415-46.461-33.17-76.674-33.17-58.011 0-105.042 47.029-105.042 105.039 0 8.233.929 16.25 2.72 23.939-87.3-4.382-164.701-46.2-216.509-109.753-9.042 15.514-14.223 33.558-14.223 52.809 0 36.444 18.544 68.596 46.73 87.433a104.614 104.614 0 01-47.577-13.139c-.01.438-.01.878-.01 1.321 0 50.894 36.209 93.348 84.261 103a105.245 105.245 0 01-27.674 3.687c-6.769 0-13.349-.66-19.764-1.888 13.368 41.73 52.16 72.104 98.126 72.949-35.95 28.176-81.243 44.967-130.458 44.967-8.479 0-16.84-.496-25.058-1.471 46.486 29.807 101.701 47.197 161.021 47.197 193.211 0 298.868-160.062 298.868-298.872 0-4.554-.104-9.084-.305-13.59 20.526-14.809 38.335-33.309 52.417-54.373z"></path>
                    </svg>
                </a>   

            </Col>
            <Col md={12}>
                <br />
            </Col>


        </div>
    );
}