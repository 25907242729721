import { Container } from 'react-bootstrap';
import styles from './styles/enConstruccion.module.css';

export function NotFound() {
    return (
        <div className={styles.Contenedor}>
            <Container>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <h1>Pagina no Encontrada</h1>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Container>
        </div>
    );
}