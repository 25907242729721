import React from 'react';
import './styles/App.css';
import {
  BrowserRouter,
  Route, Routes
} from 'react-router-dom';
import Home from '../pages/Home';
import { MiEquipo } from '../pages/MiEquipo';
import Error404 from '../pages/Error404';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/Mi-Equipo" element={<MiEquipo/>} />
        <Route exact path="/Hola-Mundo" element={<div>Hola mundo</div>} />
        {/*404 route*/}
        <Route path="*" element={<Error404/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

