import { Container } from 'react-bootstrap';
import styles from './styles/fotos.module.css';
import img6 from "../img/photos/broncos6.jpg";
import tkl1 from "../img/photos/tackle/tackle1.jpg";
import tkl2 from "../img/photos/tackle/tackle2.jpg";
import tkl3 from "../img/photos/tackle/tackle3.jpg";
import tkl4 from "../img/photos/tackle/tackle4.jpg";
import tkl5 from "../img/photos/tackle/tackle5.jpg";
import tkl6 from "../img/photos/tackle/tackle6.jpg";
import tkl7 from "../img/photos/tackle/tackle7.jpg";
import tkl8 from "../img/photos/tackle/tackle8.jpg";
import tkl9 from "../img/photos/tackle/tackle9.jpg";
import tkl10 from "../img/photos/tackle/tackle10.jpg";
import tkl11 from "../img/photos/tackle/tackle11.jpg";
import flagf1 from "../img/photos/flag-femenino/flagf1.jpg";
import flagf2 from "../img/photos/flag-femenino/flagf2.jpg";
import flagf3 from "../img/photos/flag-femenino/flagf3.jpg";
import flagf4 from "../img/photos/flag-femenino/flagf4.jpg";
import flagf5 from "../img/photos/flag-femenino/flagf5.jpg";
import flagf6 from "../img/photos/flag-femenino/flagf6.jpg";
import flagf7 from "../img/photos/flag-femenino/flagf7.jpg";
import flagf8 from "../img/photos/flag-femenino/flagf8.jpg";
import flagf9 from "../img/photos/flag-femenino/flagf9.jpg";
import flagf10 from "../img/photos/flag-femenino/flagf10.jpg";
import flagf11 from "../img/photos/flag-femenino/flagf11.jpg";
import flagf13 from "../img/photos/flag-femenino/flagf13.jpg";
import flagf14 from "../img/photos/flag-femenino/flagf14.jpg";
import flagf15 from "../img/photos/flag-femenino/flagf15.jpg";
import flagf16 from "../img/photos/flag-femenino/flagf16.jpg";
import flagm from "../img/photos/broncos9.jpg";
import flagm1 from "../img/photos/flag-masculino/flagm1.jpg";
import flagm2 from "../img/photos/flag-masculino/flagm2.jpg";
import flagm3 from "../img/photos/flag-masculino/flagm3.jpg";
import flagm4 from "../img/photos/flag-masculino/flagm4.jpg";
import flagm5 from "../img/photos/flag-masculino/flagm5.jpg";
import flagm6 from "../img/photos/flag-masculino/flagm6.jpg";
import flagm7 from "../img/photos/flag-masculino/flagm7.jpg";
import flagm8 from "../img/photos/flag-masculino/flagm8.jpg";
import flagm9 from "../img/photos/flag-masculino/flagm9.jpg";
import flagm10 from "../img/photos/flag-masculino/flagm10.jpg";
import flagm11 from "../img/photos/flag-masculino/flagm11.jpg";
import flagm12 from "../img/photos/flag-masculino/flagm12.jpg";
import flagm13 from "../img/photos/flag-masculino/flagm13.jpg";
import flagm14 from "../img/photos/flag-masculino/flagm14.jpg";




export function Fotos() {
    return (
        <div className={styles.Contenedor}>
            <Container>
                <h1 className={styles.titulo}>Fotos</h1>
                <br />
                <h2 className={styles.subtitulo}><li>Equipo de Tackle</li></h2>
                <br />
                <div className={styles.mosaic}>
                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + img6 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + tkl1 + ")" }}
                    ></div>

                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + tkl4 + ")" }}
                    ></div>

                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + tkl3 + ")" }}
                    ></div>

                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + tkl2 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + tkl5 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + tkl9 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + tkl6 + ")" }}
                    ></div>

                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + tkl7 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + tkl8 + ")" }}
                    ></div>

                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + tkl10 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + tkl11 + ")" }}
                    ></div>
                </div>
                <br />
                <br />
                <br />
                <h2 className={styles.subtitulo}><li>Equipo de Flag Femenino</li></h2>
                <br />
                <div className={styles.mosaic}>
                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagf1 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagf14 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagf3 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagf4 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagf5 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagf6 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagf7 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card}`}
                        style={{ backgroundImage: "url(" + flagf8 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card}`}
                        style={{ backgroundImage: "url(" + flagf9 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagf10 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.tall} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagf11 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide} ${styles.tall}`}
                        style={{ backgroundImage: "url(" + flagf13 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide} ${styles.tall}`}
                        style={{ backgroundImage: "url(" + flagf2 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.tall}`}
                        style={{ backgroundImage: "url(" + flagf15 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.tall}`}
                        style={{ backgroundImage: "url(" + flagf16 + ")" }}
                    ></div>

                </div>
                <br />
                <br />
                <br />
                <h2 className={styles.subtitulo}><li>Equipo de Flag Masculino</li></h2>
                <br />
                <div className={styles.mosaic}>
                    <div
                        className={`${styles.card} ${styles.wide} ${styles.tall}`}
                        style={{ backgroundImage: "url(" + flagm + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagm1 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagm2 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide} ${styles.tall}`}
                        style={{ backgroundImage: "url(" + flagm3 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide} ${styles.tall}`}
                        style={{ backgroundImage: "url(" + flagm4 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide} ${styles.tall}`}
                        style={{ backgroundImage: "url(" + flagm5 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagm6 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagm7 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide} ${styles.tall}`}
                        style={{ backgroundImage: "url(" + flagm9 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagm8 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagm11 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide} ${styles.tall}`}
                        style={{ backgroundImage: "url(" + flagm10 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagm12 + ")" }}
                    ></div>
                    <div
                        className={`${styles.card} ${styles.wide}`}
                        style={{ backgroundImage: "url(" + flagm14 + ")" }}
                    ></div>
                </div>
                <br />
                <br />
                <br />
                <br />
            </Container>
        </div>
    );
}