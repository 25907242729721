import { Container, Col, Row, Card } from "react-bootstrap";
import logoFecofa from '../img/icons/logo fecofa.png'
import logoGmail from '../img/icons/gmail.png'
import logoPhone from '../img/icons/phone.png'
import logoInstagram from '../img/icons/instagram.png'

export function Footer() {
    return (
        <footer>
            <a id="footer"></a>
            <Container>
                <Row>
                    <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3}>
                        <Card className="TarjetasFooter">
                            
                            <h2>Broncos Bello</h2>
                            
                            <p>Somos el Club Broncos Bello de Football Americano, transformación social a través del deporte.</p>
                            <p>#WeAreNorth</p>
                            
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3}>
                        <Card className="TarjetasFooter">
                            
                            <h2>Sedes</h2>
                            
                            <ul>
                                <li>Auxiliar Tulio Ospina</li>
                                <li>Marte 2 Atanasio Girardot</li>
                            </ul>
                            
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3}>
                        <Card className="TarjetasFooter" animation="glow">
                            
                            <h2>Contacto</h2>
                            
                            <a href="https://www.flaticon.es/iconos-gratis/gmail" title="gmail iconos"><img src={logoGmail} className="iconsFooter" alt="gmail"/></a>
                            <p className="logoText">secretariabroncos@gmail.com</p>

                            <a href="https://www.flaticon.com/free-icons/phone" title="phone icons"><img src={logoPhone} className="iconsFooter" alt="phone"/></a>
                            <p className="logoText">+57 316 777 53 80</p>

                            <a href="https://www.flaticon.com/free-icons/instagram" title="instagram icons"><img src={logoInstagram} className="iconsFooter" alt="instagram"/></a>

                            <a href="https://www.instagram.com/broncosbello/" className="aText">@BroncosBello</a>
                            
                            
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3}>
                        <Card className="TarjetasFooter">
                            
                            <img src={logoFecofa} className="logoFecofa" alt="fecofa" />
                            
                        </Card>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}