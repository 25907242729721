import { NavbarBroncos } from "./navbar"
//import { Carousel} from "react-bootstrap";
import img1 from "../img/photos/broncos1.jpg";
//import img4 from "../img/photos/broncos4.jpg";
import img6 from "../img/photos/broncos6.jpg";
import img7 from "../img/photos/broncos7.jpg";
import img8 from "../img/photos/broncos8.jpg";
import img9 from "../img/photos/broncos9.jpg";
import "./styles/App.css";
import React, { useState, useEffect } from 'react'

//al añadir imagenes, solo tocar la lista ListImage mientras se agrega una parte mas automatizada
const listImage = [img6,img1,img7,img8,img9];

export function Header() {

    const [value, setValue] = useState(0);

    useEffect(() => {
        setInterval(() => {
            setValue((v)=>(v===listImage.length-1?0:v+1));
        }, 30000);
    }, []);

    return (
        <div>
            <header style={{
                backgroundImage: "url(" + listImage[value] + ")"
            }}>
                <NavbarBroncos />
            </header>
        </div>
    )
}




/*
function changeImage() {

    if (i >= listImage.length - 1) {
        i = 0;
    } else {
        i++;
    }
    console.log("i:" + i);
}

export function Header() {

    changeImage()
    const element = (
        <div>
            <header style={{
                backgroundImage: "url(" + listImage[i] + ")"
            }}>
                <NavbarBroncos />
            </header>
        </div>
    )

    return(element)  
}
*/




/*
export function Header() {
    return (
        <div>
            <Carousel>
                <Carousel.Item interval={1000}>
                    <img
                        className="d-block w-100"
                        src={img6}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={500}>
                    <img
                        className="d-block w-100"
                        src={img1}
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                        <h3>Second slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="../src/img/photos/broncos6.jpg"
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

        </div>
    );
}
*/