import React from 'react';
import { Header } from '../components/header';
import { Whatsapp } from '../components/whatsapp';
import { SocialNetworks } from '../components/socialNetwork';
import { Footer } from '../components/footer';
import { NotFound } from '../components/NotFound';

class Error404 extends React.Component {
    render(){
        return (
            <div>
                <Header />
                <SocialNetworks />
                <NotFound />
                <Whatsapp />
                <Footer />
            </div>
        );
    }
}

export default Error404;