import React from 'react';
import { Header } from '../components/header';
import { Whatsapp } from '../components/whatsapp';
import { SocialNetworks } from '../components/socialNetwork';
import { Footer } from '../components/footer';
import { Fotos } from '../components/fotos';
import { Contenido } from '../components/contenido';

class Home extends React.Component {
    render(){
        return (
            <div>
                <Header />
                <SocialNetworks />
                <Contenido />
                <Fotos/>
                <Whatsapp />
                <Footer />
            </div>
        );
    }
}

export default Home;